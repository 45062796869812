<template>
  <div>
    <AppCard
      class="settings-page__panel"
      body-class="flex column q-pa-lg"
      header-color="bg-grey-11"
      :title="$t('label.setting')"
      bordered
    >
      <div class="form-builder__option img-selector">
        <div
          v-for="type in $options.formTypes"
          :key="type"
          :class="['option', { active: isTypeActive(type) }]"
          @click="formPart.webinarStyle = type"
        >
          <q-img
            :src="require(`assets/form_${type}.svg`)"
            class="option-img"
            width="80px"
            height="80px"
          />
          {{ $t(`label.${type}`) }}
        </div>
      </div>
      <div class="form-builder__option">
        <AppToggle
          v-model="formPart.hasMultistep"
          :label="$t('label.multistepForm')"
          left-label
        />
      </div>
      <PopupButtonSettings
        v-model="formPart"
        class="form-builder__option form-builder__option_horizontal"
      />
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.fieldsLabelsEnabled"
          :label="$t('label.fieldsLabelsEnabled')"
          left-label
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.hasTermsConfirmation"
          :label="$t('label.termsConfirmation')"
          left-label
        />
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            v-if="formPart.hasTermsConfirmation"
            class="form-builder__option_horizontal"
          >
            <AppInput
              v-model="formPart.termsConfirmationText"
              hide-bottom-space
              type="textarea"
              class="app-input_textarea"
              :label="$t('label.termsConfirmText')"
              :hint="$t('label.termsConfirmTextHint')"
              autogrow
            />
            <AppInput
              v-model="formPart.termsConfirmationLink"
              placeholder="https://"
              :label="$t('label.linkToTermsConfirmation')"
              :rules="[rules.URL]"
              type="url"
            />
            <AppToggle
              v-model="formPart.termsConfirmationActiveByDefault"
              :label="$t('label.activeByDefault')"
              left-label
            />
          </div>
        </transition>
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.hasNewsletterConfirmation"
          :label="$t('label.newsletter')"
          left-label
        />
        <template v-if="formPart.hasNewsletterConfirmation">
          <div
            v-if="!hasAutoresponders"
            class="newsletter-help-block"
          >
            <span class="fa-stack">
              <i class="fa-regular fa-circle fa-stack-2x"/>
              <i class="fa fa-info fa-stack-1x"/>
            </span>
            <div>{{ $t('text.newsletterAutoresponderReminder') }}</div>
          </div>
          <AppInput
            v-model="formPart.newsletterConfirmationText"
            type="textarea"
            class="app-input_textarea"
            :label="$t('label.text')"
            autogrow
          />
          <AppInput
            v-model="formPart.newsletterConfirmationTag"
            :rules="[rules.NEWSLETTER_TAG]"
            :label="$t('label.subscriptionTag')"
          />
          <AppToggle
            v-model="formPart.newsletterConfirmationRequired"
            :label="$t('label.required')"
            left-label
          />
          <AppToggle
            v-model="formPart.newsletterConfirmationActiveByDefault"
            :label="$t('label.activeByDefault')"
            left-label
          />
        </template>
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="formPart.hasAffiliateBadge"
          :label="$t('label.affiliateBadge')"
          left-label
        />
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <AppInput
            v-if="formPart.hasAffiliateBadge"
            v-model="formPart.affiliateBadgeId"
            :rules="[rules.REQUIRED]"
            :label="$t('label.affiliateBadgeId')"
          />
        </transition>
      </div>
      <div class="form-builder__option form-builder__option_horizontal relative-position">
        <AppInput
          v-model="formPart.title"
          :rules="[rules.REQUIRED]"
          :label="$t('label.formTitle')"
          type="text"
        />
        <AppSelect
          v-model="formPart.language"
          :options="$options.languageList"
          :label="$t('label.formLang')"
          emit-value
          map-options
        />
        <AppInput
          v-model="formPart.linkThanksPage"
          :rules="[rules.URL]"
          :label="$t('label.linkToPageThanksYou')"
          type="text"
        />
        <div class="page-hint page-hint-link">
          {{ $t('text.linkToPageThanksYouHint') }}
        </div>
      </div>
    </AppCard>
    <div class="page-hint">
      {{ $t('text.settingsPageHint') }}
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import PopupButtonSettings from 'common/components/PopupButtonSettings';
import WEBINAR_STYLES from 'common/enums/webinarStyles';
import rules from 'common/helpers/rules';
import builderMixin from './mixins/builderMixin';

export default {
  name: 'SettingPage',
  stepName: ENUMS.STEPS.SETTINGS,
  languageList: [
    { label: 'English', value: 'en_EN' },
    { label: 'Deutsch', value: 'de_DE' },
  ],
  formTypes: Object.values(WEBINAR_STYLES),
  components: {
    AppCard,
    AppSelect,
    AppInput,
    AppToggle,
    PopupButtonSettings,
  },
  mixins: [builderMixin],
  computed: {
    rules: () => rules,
    hasAutoresponders() {
      return this.form[ENUMS.STEPS.AUTORESPONDERS].autoresponders.length > 0;
    },
  },
  methods: {
    isTypeActive(type) {
      return this.formPart.webinarStyle === type;
    },
  },
};
</script>

<style scoped lang="scss">
.settings-page__panel {
  .form-data-button {
    transition: all 0.5s ease;
    border: 1px solid $grey-2;
    border-radius: $radius-6;
  }

  .form-data-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    padding: 18px 0 !important;
  }

  .confirm-text-input {
    align-items: start;
  }

  .newsletter-help-block {
    display: flex;
    gap: 10px;
    padding: 15px 70px 15px 24px;
    background: rgba(0, 214, 162, 0.2);
    border: 1px solid $primary;
    border-radius: $radius-6;
    position: relative;

    &:after {
      content: '';
      background: $primary;
      position: absolute;
      height: 20px;
      width: 1px;
      right: 21px;
      top: -10px;
    }

    span {
      font-size: 10px;
      color: $primary;
      margin-top: 3px;
    }
  }

  .form-builder__option_horizontal {
    .app-input,
    .app-select {
      grid-template-columns: 1fr 2.8fr;
    }
  }
}

.page-hint {
  &-link {
    top: 181px;
    left: 517px;
  }
}
</style>
